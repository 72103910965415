const ActividateConditionSettings = {
  firstLevelCondition: {
    allowMultiple: false,
    conditions: {
      spentValue: {
        combination: [],
      },
      productQuantity: {
        combination: [],
      },
    },
  },
  secondLevelCondition: {
    conditions: {
      visitBehavior: {
        combination: [],
      },
    },
  },
  thirdLevelCondition: {
    allowMultiple: false,
    conditions: {
      luckTimes: {
        combination: [],
      },
      stamp: {
        combination: [],
      },
    },
  },
}

const ActivateConditionsMap = {
  spentValue: {
    level: 'firstLevelCondition',
    type: 'spentValue',
  },
  productQuantity: {
    level: 'firstLevelCondition',
    type: 'productQuantity',
  },
  visitBehavior: {
    level: 'secondLevelCondition',
    type: 'visitBehavior',
  },
  luckTimes: {
    level: 'thirdLevelCondition',
    type: 'luckTimes',
  },
  stamp: {
    level: 'thirdLevelCondition',
    type: 'stamp',
  },
}

export { ActividateConditionSettings, ActivateConditionsMap }
