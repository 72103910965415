import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleBenefitActions'

export const initialState = {
  accelerators: null,
  accelerator: null,
  acceleratorIndex: null,
  products: null,
  discount: null,
  voucher: null,
  thirdPartyVoucher: null,
  credit: null,
  pinCode: null,
  productFinalPrice: null,
  creditRedemption: null,
  grantParticipationAuthorization: null,
  grantParticipationAuthorizationDetails: null,
  luckyNumbers: null,
}

const saleBenefitReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draft => {
      Object.keys(payload).forEach(benefit => {
        draft[benefit] = payload[benefit]
      })
    })
  },
  [Actions.SET_BENEFIT](state, { payload: { type, value } }) {
    return produce(state, draft => {
      draft[type] = value
    })
  },
  [Actions.REMOVE_BENEFIT](state, { payload: { type } }) {
    return produce(state, draft => {
      draft[type] = null
      draft.accelerators = []
    })
  },
  [Actions.SET_ACCELERATOR_IDENTIFIER](state, { payload: identifier }) {
    return produce(state, draft => {
      if (draft.accelerator) {
        draft.accelerator.conditions.product.identifiers = Array.isArray(identifier)
          ? [...state.accelerator.conditions.product.identifiers, ...identifier]
          : [...state.accelerator.conditions.product.identifiers, identifier]
      } else {
        draft.accelerator = {
          conditions: {
            product: {
              required: true,
              identifiers: Array.isArray(identifier) ? identifier : [identifier],
            },
          },
        }
      }
    })
  },
  [Actions.SET_SELECTED_ACCELERATOR](state, { payload: index }) {
    return produce(state, draft => {
      if (index !== null) {
        draft.accelerator = draft.accelerators[index]
        draft.acceleratorIndex = index
      } else {
        draft.accelerator = null
        draft.acceleratorIndex = null
      }
    })
  },
  [Actions.REMOVE_ACCELERATOR_IDENTIFIER](state, { payload: index }) {
    return produce(state, draft => {
      const newIdentifiers = []
      state.accelerator.conditions.product.identifiers.map((identifier, i) => {
        if (index !== i) {
          newIdentifiers.push(identifier)
        }
      })
      draft.accelerator.conditions.product.identifiers = newIdentifiers
    })
  },
  [Actions.SET_ACCELERATOR](state, { payload: accelerator }) {
    return produce(state, draft => {
      draft.accelerators = [...state.accelerators, accelerator]
      draft.accelerator = null
      draft.acceleratorIndex = null
    })
  },
  [Actions.REMOVE_ACCELERATOR](state, { payload: index }) {
    return produce(state, draft => {
      const newIAccelerators = []
      state.accelerators.map((accelerator, i) => {
        if (index !== i) {
          newIAccelerators.push(accelerator)
        }
      })
      draft.accelerators = newIAccelerators
    })
  },
  [Actions.EDIT_ACCELERATOR](state, { payload: accelerator }) {
    return produce(state, draft => {
      const newIAccelerators = []
      state.accelerators.map((a, i) => {
        if (draft.acceleratorIndex !== i) {
          newIAccelerators.push(a)
        } else {
          newIAccelerators.push(accelerator)
        }
      })
      draft.accelerators = newIAccelerators
      draft.accelerator = null
      draft.acceleratorIndex = null
    })
  },
  [Actions.CLEAN]() {
    return initialState
  },
  [Actions.SET_PARTICIPATION_AUTHORIZATION](state, { payload }) {
    return produce(state, draft => {
      draft.grantParticipationAuthorization.saleIds = payload
    })
  },
  [Actions.REQUEST_GET_LIST_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.grantParticipationAuthorizationDetails = payload
    })
  },
  [Actions.REMOVE_GRANT_PARTICIPATION_AUTHORIZATION_DETAILS](state) {
    return produce(state, draft => {
      draft.grantParticipationAuthorizationDetails = null
    })
  },
})

export default saleBenefitReducer
