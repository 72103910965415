import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const productListEndpoints = environment.managerApi.endpoints.productList

export async function create(data) {
    return EffectUtility.post(productListEndpoints.add, data)
}

export async function update(id, data) {
    return EffectUtility.put(productListEndpoints.update.replace(':id', id), data)
}

export async function search(filter, pagination) {
    return EffectUtility.post(productListEndpoints.search, { filter: { ...filter }, pagination: { ...pagination } })
}

export const importProduct = async (productListId, file) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('productListId', productListId);
    await EffectUtility.postFormData(productListEndpoints.import, formData)
}
