import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import { ProductVisibility } from '../../product/models/ProductVisibility'
import * as Actions from './SaleProductsActions'

export const initialState = {
  selected: {
    allowAnyProduct: false,
    products: [],
    nonEligibleProducts: [],
    numberOfRows: 0,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      name: null,
      type: null,
      code: null,
      chainId: null,
      category: null,
      manufacturerName: null,
      brandName: null,
      selected: true,
      visibility: null,
    },
  },
  allProducts: [],
  available: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      name: null,
      type: null,
      code: null,
      chainId: null,
      category: null,
      manufacturerName: null,
      visibility: ProductVisibility.Private,
      brandName: null,
      selected: false,
    },
    numberOfRows: 0,
    products: [],
  },
}

const saleProductsReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_SELECTED_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.selected.products = payload.data
      draft.selected.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_SEARCH_AVAILABLE_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.available.products = payload.data
      draft.available.numberOfRows = payload.numberOfRows
    })
  },

  [Actions.REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.selected.nonEligibleProducts = payload.data
      draft.selected.numberOfRows = payload.numberOfRows
    })
  },

  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draft => {
      draft.selected.filter = payload
    })
  },
  [Actions.SET_SELECTED_FILTER](state, { payload }) {
    return produce(state, draft => {
      draft.selected.filter = { ...payload, selected: true }
    })
  },
  [Actions.SET_ALLOW_ANY_PRODUCT_FINISHED](state) {
    return produce(state, draft => {
      draft.selected.allowAnyProduct = !state.selected.allowAnyProduct
      draft.selected.products = []
    })
  },
  [Actions.RESET_SELECTED_FILTER](state) {
    return produce(state, draft => {
      draft.selected.filter = initialState.selected.filter
    })
  },
  [Actions.SET_FILTER_AVAILABLE](state, { payload }) {
    return produce(state, draft => {
      draft.available.filter = payload
    })
  },
  [Actions.RESET_SELECTED_FILTER_AVAILABLE](state) {
    return produce(state, draft => {
      draft.available.filter = initialState.available.filter
    })
  },
  [Actions.SET_PAGINATION_AVAILABLE](state, { payload }) {
    return produce(state, draft => {
      draft.available.pagination = payload
    })
  },
  [Actions.SET_PAGINATION_SELECTED](state, { payload }) {
    return produce(state, draft => {
      draft.selected.pagination = payload
    })
  },
  [Actions.RESET_AVAILABLE_PAGINATION](state) {
    return produce(state, draft => {
      draft.available.pagination = initialState.available.pagination
    })
  },
  [Actions.RESET_SELECTED_PAGINATION](state) {
    return produce(state, draft => {
      draft.selected.pagination = initialState.selected.pagination
    })
  },
})

export default saleProductsReducer
