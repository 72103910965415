import { combineReducers } from 'redux'

import searchReducer from './search/SaleSearchReducer'
import editorReducer from './editor/SaleEditorReducer'
import basicInfoReducer from './basic-info/SaleBasicInfoReducer'
import activateConditionsReducer from './activate-conditions/SaleActivateConditionReducer'
import stamp from './activate-conditions/stamp/StampReducer'
import storesReducer from './stores/SaleStoreReducer'
import saleBenefitReducer from './benefit/SaleBenefitReducer'
import saleProductsReducer from './products/SaleProductsReducer'
import saleSummaryReducer from './summary/SaleSummaryReducer'
import saleEligiblesReducer from './eligibles/SaleEligiblesReducer'
import saleFeedbackMessagesReducer from './feedback-messages/SaleFeedbackMessagesReducer'
import salePaymentReducer from './payment/SalePaymentReducer'
import delimitationsReducer from './delimitations/DelimitationsReducer'
import eligibleAttributes from './attributes/SaleAttributesReducer'
import saleNotification from './notification/SaleNotificationReducer'
import advertisementReducer from './advertisement/SaleAdvertisementReducer'

const saleRootReducer = combineReducers({
  search: searchReducer,
  editor: editorReducer,
  basicInfo: basicInfoReducer,
  activateConditions: activateConditionsReducer,
  stamp: stamp,
  eligibles: saleEligiblesReducer,
  benefits: saleBenefitReducer,
  stores: storesReducer,
  products: saleProductsReducer,
  payment: salePaymentReducer,
  feedbackMessages: saleFeedbackMessagesReducer,
  summary: saleSummaryReducer,
  delimitations: delimitationsReducer,
  advertisement: advertisementReducer,
  attributes: eligibleAttributes,
  notification: saleNotification,
})

export default saleRootReducer
